<template> 
 <v-container>
  <div class="inputDate">
    <label>
        <p>{{ label }}</p>
    <input 
    type="date"
    v-bind="$attrs" 
    :value="modelValue" 
    @input="updateValue"
    required
    style="border:1px solid #ccc;
     border-radius:4px;
     padding:8px 16px"
    />
   </label>
  </div>
 </v-container>
</template>
 
<script setup>
const props = defineProps({
    label: {
        type: String,
        default: ""
    },

    modelValue: {
        type: [Date, String],
        default: ""
    }
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {
    emit('update:modelValue', event.target.value);
}

</script>