import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, query, where, getDocs } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyCmQgMnR_ICP725-K955zvXl_dGTOn_-_M",
  authDomain: "medisure-hr.firebaseapp.com",
  projectId: "medisure-hr",
  storageBucket: "medisure-hr.appspot.com",
  messagingSenderId: "872457171489",
  appId: "1:872457171489:web:cd851084a1468aabf16960",
  measurementId: "G-71DBCCS6B9"
};

  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  export const db = getFirestore(app);
  
  export const employees = collection(db, "users");
  export const roles = collection(db, "roles");

  export const getUserState = () =>
    new Promise((resolve, reject) =>
      onAuthStateChanged(auth, resolve, reject)
  )

  // Function to get userProfile whether HR or employee
  export const getProfile = async (id) => {
    let data = {}
    const user = employees;
    const details = query(user, where("authId", "==", id));
    const querySnapshot = await getDocs(details);
    querySnapshot.forEach((doc) => {
     data = { ...doc.data() , id: doc.id } 
    })
    return data
}

//  export const getUser = async id => {
//   const user = await employees.doc(id).get();
//   return user.exists ? user.data() : null
//  }

// var pointerX = -1;
// var pointerY = -1;
// document.onmousemove = function(event) {
// 	pointerX = event.pageX;
// 	pointerY = event.pageY;
// }
// setInterval(pointerCheck, 1000);

// export function pointerCheck() {
// 	console.log('Cursor at: '+pointerX+', '+pointerY);
// }