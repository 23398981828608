<template>
   <v-app>
    <v-app-bar height="70" color="primary" flat>
      <NavBarEdit @open="showEdit = true" />
    </v-app-bar>
    <v-navigation-drawer color="#f0f8fa">     
        <v-card-item align="center">

            <v-avatar size="80" rounded="1">
              <v-img :src="store.photoUrl" />
            </v-avatar>

            <v-card-title>{{ store.firstName}}</v-card-title>

            <v-card-subtitle class="text-center">
              <p>{{ store.designation }}</p>
            </v-card-subtitle>
            
        </v-card-item>      
    </v-navigation-drawer>
    <v-main>
     <v-container fluid class="main-container">

     <v-row>
      <v-col cols="12" outlined>
        <v-card>
         <v-card-title>Personal Information</v-card-title>
         <v-form>
          <v-card-text>
            <v-text-field 
             v-model="store.firstName"
             label="First Name"
             maxLength="20"
             ></v-text-field>

             <v-text-field 
             v-model="store.middleName"
             label="Middle Name"
             maxLength="20"
             ></v-text-field>

             <v-text-field 
             v-model="store.lastName"
             label="Last Name"
             maxLength="20"
             ></v-text-field>

             <v-card-actions>
              <GenericBtn 
               buttonText="Update"
               color="green" 
               variant="outlined"
               @click="personalUpdate"
               />
             </v-card-actions>
          </v-card-text>
         </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" outlined>
       <v-card>
        <v-card-title>
          <v-row>
            <v-col>Contact Information</v-col>
            <v-col><AddContacts header="Emergency Contact"/></v-col>
          </v-row>       
        </v-card-title>
        <v-form>
         <v-card-text>
          <v-text-field 
           v-model="store.contactNumber" 
           label="Mobile Number"
           maxLength="11"
          ></v-text-field>

          <v-text-field 
           v-model="store.emailAddress" 
           label="Email Address"
           disabled
          ></v-text-field>

          <v-text-field 
           v-model="store.emergencyName" 
           label="In case of emergency notify:"
           disabled
           ></v-text-field>

           <v-text-field 
           v-model="store.emergencyNumber" 
           label="Contact him / her in this number:"
           disabled
           ></v-text-field>

          <v-card-actions>
              <GenericBtn 
               buttonText="Update"
               color="green" 
               variant="outlined"
               @click="contactUpdate"
               />
             </v-card-actions>
         </v-card-text>
        </v-form>
       </v-card>
      </v-col>

      <v-col>
       <v-card>
        <v-card-title>Documents Uploaded</v-card-title>
        <v-form>
         <v-card-text>
          <FileDisplay />
         </v-card-text>
        </v-form>
       </v-card>
      </v-col>
     </v-row>

    </v-container>
    </v-main>
   </v-app>
    <!-- <div v-if="showEdit">
        <EditUserAlternate  @close="showEdit = false"/>
    </div> -->
</template>

<script setup>
import { useStore } from '@/stores/userStore';
import { doc, updateDoc } from "firebase/firestore";
import { employees } from '../../firebase'
import GenericBtn from '@/components/utilities/buttons/GenericBtn.vue';
import NavBarEdit from '@/components/utilities/NavBarEdit.vue';
import FileDisplay from '@/components/humanResource/filedialog/FileDisplay.vue'; 
import AddContacts from '@/components/utilities/AddContacts.vue';
import NameFields from '@/components/utilities/NameFields.vue';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { ref, onMounted } from 'vue';

        const store = useStore();
        const fill = store.fill();
        let showEdit = ref(false);
        const auth = getAuth();
        const userId = ref("");

        onMounted(() => {
          let winPath = window.location.href;
          userId.value = winPath.split("/").pop();          
          store.selectedUserUid = userId.value;
        });

        const personalUpdate = async () => {
            const docRef = doc(employees, userId.value);
            const personal = {
              firstName: store.firstName,
              middleName: store.middleName,
              lastName: store.lastName,
            }
            await updateDoc(docRef, personal);
            alert('user was updated!');
        }

         const contactUpdate = async () => {
           const docRef = doc(employees, userId.value);
           const contact = {
            contactNumber: store.contactNumber,
           }
           await updateDoc(docRef, contact);
           alert('user was updated!');
        }       

</script>