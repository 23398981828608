<template>
 <v-container class=" mb-6">
  <v-row>
    <v-col>
      <v-text-field
       label="House No. / Street"     
       v-model="houseNumber"
       @update:model-value="houseNumberSelect"
       :rules="[rules.required]"
      >
     </v-text-field>
    </v-col>
    <v-col>
      <v-text-field
       label="Barangay"
       v-model="barangay"
       @update:model-value="brgySelected"
       :rules="[rules.required]"
      >
     </v-text-field>
    </v-col>
    <v-col>
      <v-text-field
       label="Town / City"
       v-model="municipality"
       @update:model-value="citySelected"
       :rules="[rules.required]"
      >
     </v-text-field>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="4">
      <v-text-field
       label="Province"
       v-model="province"
       @update:model-value="provinceSelected"
       :rules="[rules.required]"
      >
     </v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
       label="Zip code"
       v-model="zipCode"
       hide-details
       @update:model-value="zipCodeSelected"
       :rules="[rules.required]"
      >
     </v-text-field>
    </v-col>
  </v-row>
 </v-container>
</template>

<script setup>
import { ref, onMounted} from 'vue';
import { rules } from './validation.js';
import { emptyAddress } from './others';

const props = defineProps({
  prefilled: {
    type: Object,
    default: {}
  }
}); 

const emit = defineEmits([
  "onLocation"
]);

const address = ref(emptyAddress());
const houseNumber = ref("");
const barangay = ref("");
const municipality = ref("");
const province = ref("");
const zipCode = ref("");

onMounted(() => {
  barangay.value = props.prefilled.barangay || "";
  houseNumber.value = props.prefilled.houseNumber || "";
  municipality.value = props.prefilled.municipality || "";
  province.value = props.prefilled.province || "";
  zipCode.value = props.prefilled.zipCode || "";

  address.value.barangay = barangay.value;
  address.value.houseNumber = houseNumber.value;
  address.value.municipality = municipality.value;
  address.value.province = province.value;
  address.value.zipCode = zipCode.value;

  emit("onLocation", address.value );
});

const houseNumberSelect = () => {
  address.value.houseNumber = houseNumber.value;
  emit("onLocation", address.value);
};

const brgySelected = () => {
  address.value.barangay = barangay.value;
  emit("onLocation", address.value);
};

const citySelected = () => {
  address.value.municipality = municipality.value;
  emit("onLocation", address.value);
};

const provinceSelected = () => {
  address.value.province = province.value;
  emit("onLocation", address.value);
};

const zipCodeSelected = () => {
  address.value.zipCode = zipCode.value;
  emit("onLocation", address.value);
}
</script>