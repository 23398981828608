<template>
<v-app>
  
    <TitleBar />
  
  <v-main id="main">
  <v-row class="justify-center mt-16">
    <v-card max-width="400px" id="card">
      <div>
        <img :src="store.photoUrl" alt="picture" id="myImg">
      </div>

      <v-card-title class="text-center">
        <h2><p>{{ store.firstName }} {{ store.lastName }}</p></h2>
      </v-card-title>

      <v-card-subtitle class="text-center">
        <p>{{ store.designation }}</p>
      </v-card-subtitle>

      <v-card-text class="text-center">     
        <p>Employee Number: {{ store.employeeNumber }}</p>
        <div>
          <p>mso.admin@medisureonline.com<br>
          HR mobile number: +639201562291</p>
        </div>
        <br/>
        <div >
          <qrcode-vue v-if="store.str" :value="store.str"  level="H" /> 
        </div>
      </v-card-text>
      
    </v-card>
    <!-- <v-card  max-width="450"  class="ml-5">
      
      <v-toolbar dark prominent >
        <v-toolbar-title  class="text-center">Files</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <span><strong>Files</strong>:</span> <span><FileDisplay /></span>
        <h4>Date of Birth: {{ store.date.dateOfBirth }}</h4>
        <h4>Date Hired: {{ store.date.dateOfHiring }}</h4>     
      </v-card-text>
     
    </v-card> -->
 </v-row>

 </v-main>
</v-app> 

</template>

<script >
import { useStore } from '@/stores/userStore';
import   QrcodeVue  from 'qrcode.vue';
import GenericBtn from '../utilities/buttons/GenericBtn.vue';
import FileDisplay from './filedialog/FileDisplay.vue';
import TitleBar from '@/components/utilities/TitleBar.vue';

export default ({
  name: "ForCompany",
  components: {
    QrcodeVue,
    GenericBtn,
    FileDisplay,
    TitleBar,
  },
  setup() {
   const store = useStore();
   const fill = store.fill();
   let dialog = false;


   return {
     store,
     fill,
     dialog,
   }
  }
})
</script>
<style scoped>
/* #main {
 background: #558b2f;
} */

img {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-radius: 10px ;
}

/* #card {
  background: aliceblue;
} */

</style>