import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/humanResource/HomeView.vue';
import EmployeeView from '../views/employee/EmployeeView.vue';
import HrView from '../views/humanResource/HrView.vue';
import ForCompany from '../components/humanResource/ForCompany.vue';
import ForClient from '../components/humanResource/ForClient.vue';
import EditUser from '../components/humanResource/EditUser.vue';
import SearchBar from '../components/humanResource/SearchBar.vue';
import { getUserState } from '@/firebase.js';

const guard = async (to, from, next) => {
  
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    //meta: { guest: true }
  },
  {
    path: '/employeeView/:id',
    name: 'employeeView',
    component: EmployeeView,
    //meta: { auth: true }
  },
  {
    path: '/hrView',
    name: 'hrView',
    component: HrView,
    //meta: { auth: true }    
  },
  {
    path: '/forCompany/:id',
    name: 'forCompany',
    component: ForCompany,  
  },
  {
    path: '/forClient/:id',
    name: 'forClient',
    component: ForClient
  },
  {
    path: '/editUser/:id',
    name: 'editUser',
    component: EditUser
  },
  {
    path: '/searchBar',
    name: 'searchBar',
    component: SearchBar
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  const isAuth = await getUserState();
  if(!isAuth && from.name === 'home') {
    return false;
  }
});

export default router