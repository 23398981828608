<template>
  <v-toolbar>

      <CompanyName />        

    <v-spacer></v-spacer>  
    
      <!-- <GenericBtn buttonText="Create" @click="create" />   -->
      <AlternativePortal /> 
      <GenericBtn buttonText="Logout" @click="logout"/>                   
            
  </v-toolbar>
                     
</template>

<script>
import CompanyName from '../utilities/CompanyName.vue';
import GenericBtn from '../utilities/buttons/GenericBtn.vue';
import AlternativePortal from '@/views/humanResource/AlternativePortal.vue';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';

export default ({
    names: 'NavBar',

    components: { 
      CompanyName,
      GenericBtn,
      AlternativePortal
    },

    setup(props, { emit }) {
        const auth = getAuth();      

        const logout = () => {                     
            signOut(auth).then(() => {
            //myTimeout;
            router.push('/');
          })
          .catch(error => {
            console.log(error.message);
          })
        }

        // const create = (event) => {
        //   emit('open', event.open);
        // }

        return {
            logout,
            //create
        }
    }

})

</script>

<style scoped>
* {
  background-color: #558b2f;
}
</style>