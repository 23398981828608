<template>

  <v-container fluid justify="left">
    <v-toolbar>

    <v-toolbar-title>
      <CompanyName /> 
    </v-toolbar-title>    
       
      <!-- <GenericBtn buttonText="Update" @click="edit" />   -->
      <GenericBtn buttonText="Logout" @click="logout"/>                   
            
  </v-toolbar>
  </v-container>
                     
</template>

<script>
import CompanyName from '../utilities/CompanyName.vue';
import GenericBtn from '../utilities/buttons/GenericBtn.vue';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';

export default ({
    names: 'NavBar',

    components: { 
      CompanyName,
      GenericBtn
    },

    setup(props, { emit }) {
        const auth = getAuth();      

        const logout = () => {
            console.log('Logout');                      
            signOut(auth).then(() => {
            //myTimeout;
            router.push('/');
          })
          .catch(error => {
            console.log(error.message);
          })
        }

        //const myTimeout = setTimeout(logout, 900000);

        const edit = (event) => {
          emit('open', event.open);
        }

        return {
            logout,
            edit
        }
    }

})

</script>

<style scoped>
* {
  background-color: #558b2f;
}
</style>