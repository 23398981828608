<template>
<v-app>
<TitleBar />
<v-main id="main">
 <v-row justify="center" class="ma-5">
  <v-card height="520px" width="680px"  id="card">
    <v-card-title class="text-center">
        <span class="text-h5">Update Details</span>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container> 
         <v-row>
          <v-col>
            <v-text-field label="First Name" v-model="pushToDb.firstName"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Middle Name" v-model="pushToDb.middleName"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Last Name" v-model="pushToDb.lastName"></v-text-field>
          </v-col>
         </v-row>
         <v-divider></v-divider> <br/>

         <StatusOfEmployment 
          :fillStatus="pushToDb.status"
          :key="forceRefresh"
          @on-status="onStatus"
         />

         <v-divider></v-divider> <br/>

         <v-row>
          <AddressFieldGroup 
            :prefilled="pushToDb.address"
            :key="forceRefresh"
            @on-location="onAddress"   
          />         
         </v-row>       

         <v-card-actions>
            
            <GenericBtn 
            buttonText="Update" 
            color="primary" 
            variant="outlined" 
            @click="changeDetails"/>

         </v-card-actions>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>

  <v-card height="48%" width="40%"  id="card" class="ml-5">
    <v-card-title class="text-center">
        <span class="text-h5">Upload files</span>
    </v-card-title>
    <v-form>
      <v-card-text align="center">
        <Uploader header="Files:" /> 
      </v-card-text>
    </v-form>
  </v-card>
  
 </v-row>
</v-main>
</v-app>
</template>

<script setup>
import { useStore } from '@/stores/userStore';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { employees } from '../../firebase';
import GenericBtn from '../utilities/buttons/GenericBtn.vue';
import Uploader from '../govBenefits/reference/UploadSample.vue';
import AddressFieldGroup from '../utilities/AddressBar.vue';
import StatusOfEmployment from './StatusOfEmployment.vue';
import TitleBar from '@/components/utilities/TitleBar.vue';
import { onMounted, ref } from 'vue';   

const addressHolder = ref({});
const dataHolder = ref({});       
const forceRefresh = ref(0);
const status = ref("");
const store = useStore(); 
const userId = ref("");
let pushToDb = ref({});

onMounted( async () => {
  let dbStore = {};
  let winPath = window.location.href;
  userId.value = winPath.split("/").pop();          
  store.selectedUserUid = userId.value;

  const docRef = doc(employees, userId.value);
  const docSnap = await getDoc(docRef);
  dbStore = docSnap.data();
  addressHolder.value = dbStore.address;
  dataHolder.value = dbStore;
  pushToDb.value = {
    address: {   
      houseNumber: addressHolder.value?.houseNumber,
      barangay: addressHolder.value?.barangay,
      municipality: addressHolder.value?.municipality,
      province: addressHolder.value?.province,
      zipCode: addressHolder.value?.zipCode
    },
    firstName: dataHolder.value.firstName,
    middleName: dataHolder.value?.middleName || "",
    lastName: dataHolder.value.lastName,
    status: dataHolder.value.status
  }
  forceRefresh.value += 1;
}); 
       
// Update profile
const changeDetails = async () => {                                                     
  const docRef = doc(employees, userId.value);
  console.log(pushToDb.value);
  await updateDoc(docRef, pushToDb.value);
  alert('user was updated!');
}

const onAddress = (address) => {
  pushToDb.value.address = { ...address };
};

const onStatus = (stat) => {
  pushToDb.value.status = stat;
}
                            
</script>

<style scoped>
#editImg {
  border-radius: 10px;
}
#card {
  background: aliceblue;
}
</style>