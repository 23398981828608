<template>
 <div v-for="(file, index) in store.benefitFile" :key="index">
   <span v-show="file.path">
    <v-dialog width="550px">
       <template v-slot:activator="{ props }">
        <v-btn flat v-bind="props" class="text-left ml-5">{{ file.path }}</v-btn>
      </template>
      <v-card-title>
        <iframe :src="file.url" width="550px" height="600px"></iframe>
         
      </v-card-title>
    </v-dialog>
    </span>
  <span v-show="!file.path">(None)</span>
 </div>
</template>

<script setup>
import { useStore } from '@/stores/userStore';

const store = useStore();
const fill = store.fill();

</script>


