<template>
  <div align="right">
    <v-dialog v-model="dialog" width="344">

      <template v-slot:activator="{ props }">
        <v-btn  
          v-bind="props"
          class="ma-1 text-white"
          color="#68b5c7"
          small >
            +
        </v-btn>
      </template>
      
      <v-card> 
       <v-card-title>{{ header }}</v-card-title>                
        <v-card-text>
         <v-container>
          <v-text-field
           label="Emergency Contact Name"
           hint="Firstname, Lastname"
           v-model="contact.emergencyName"
           maxLength="40"
          ></v-text-field>

          <v-text-field 
           label="Emergency Contact Number"
           v-model="contact.emergencyNumber"
           maxLength="11"
          ></v-text-field>
         </v-container>

         <v-card-actions>
          <GenericBtn 
           buttonText="Add"
           color="green" 
           variant="outlined"
           @click="addContact"
           @click.prevent="dialog = false"
          />
         </v-card-actions>
        </v-card-text>                
      </v-card>
      
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { employees } from '../../firebase';
import { doc, updateDoc } from "firebase/firestore";
//import { rules } from './validation.js';
import GenericBtn from '@/components/utilities/buttons/GenericBtn.vue';

const props = defineProps({
  header: {
    type: String,
    default: 'label'
  }
})

const contact = ref({
    emergencyName: "",
    emergencyNumber: ""
});
const dialog = ref(false);
const userId = ref("");

 onMounted(() => {
    let winPath = window.location.href;
    userId.value = winPath.split("/").pop();          
    //store.selectedUserUid = userId.value;
 });

const addContact = async (event) => {
    const docRef = doc(employees, userId.value);
    const test = {
        emergencyName: contact.value.emergencyName,
        emergencyNumber: contact.value.emergencyNumber
    }
    await updateDoc(docRef, test);
    alert('contacts was updated!');
    contact.value.emergencyName = "";
    contact.value.emergencyNumber = "";     
}
</script>
