<template>
   <v-text-field max-width="70" v-model="input"  prepend-inner-icon="mdi-magnify"></v-text-field>
   <div v-for="user in store.users" :key="user.id">
    <p>{{ user.firstName }}</p>
   </div>
   <div v-if="input && !searchInput.length">
    <p>No results found</p>
   </div>
</template>

<script>
import { useStore } from '@/stores/userStore';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';

export default({
    setup() {
        const store = useStore();
        const staff = store.getData();
        const users = store.users;
        let input = ref("");
        console.log(staff);

        // const filteredList = () => {
        //     return staff.filter((user) => 
        //         user.toLowerCase().includes(input.value.toLowerCase())
        //     )
        // }
        const searchInput = () => {
            input.value = staff.filter((user) => 
                user.firstName.toLowerCase().includes(input.value.toLowerCase()))
        }
        console.log(input);

        onMounted(() => {
             console.log(staff); 
        }) 

        return {
            store,
            input,
            searchInput,
            staff
        }
    }
})
</script>
