<template>
<v-app>
  <v-main id="main" >
  <v-card-title class="text-center mt-n16">
    <CompanyName />
  </v-card-title>
  <v-row class="justify-center mt-n16">

      <v-card width="400px" id="card">
        <div>
          <img :src="store.photoUrl" alt="picture" id="myImg">
        </div>

        <v-card-title class="text-center">
          <h2><p>{{ store.firstName }} {{ store.lastName }}</p></h2>
        </v-card-title>

        <v-card-subtitle class="text-center">
          <p>{{ store.designation }}</p>
        </v-card-subtitle>

        <v-card-text class="text-center">     
          <p>Employee Number: {{ store.employeeNumber }}</p>
          <div>
            <p>mso.admin@medisureonline.com<br>
            HR mobile number: +639201562291</p>
          </div>
        </v-card-text>
        
      </v-card>
  </v-row>
  </v-main>
</v-app>

</template>

<script>
import { useStore } from '@/stores/userStore';
import CompanyName from '../utilities/CompanyName.vue';

export default ({
  components: { CompanyName },

  setup() {
   const store = useStore();
   const fill = store.fill();

   return {
     store,
     fill
   }
  }

  
})
</script>

<style scoped>
#main {
 background: #558b2f;
}

img {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-radius: 10px;
}

#card {
  background: aliceblue;
}

</style>  