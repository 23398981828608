<template>
 <v-row>
  <v-col cols="4">
   <v-autocomplete
     v-model="status"
     :items="items"
     persistent-hint
     outlined
     dense
     label="Employment status"
     @update:model-value="selectedStatus"
    ></v-autocomplete>
  </v-col> 
 </v-row>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  fillStatus: {
    type: String,
    default: ""
  }
}); 

const emit = defineEmits([
  "onStatus"
]);

const items = ref([
      'consultant', 
      'ended', 
      'probation', 
      'regular'
]);
const status = ref("");
const getStatus = ref("");

onMounted(() => {
    status.value = props.fillStatus || "";

    getStatus.value = status.value
    emit("onStatus", getStatus.value);
});

const selectedStatus = () => {
    getStatus.value = status.value
    emit("onStatus", getStatus.value);
}
</script>