export function emptyAddress() {
    return {
      barangay: "",
      houseNumber: "",
      municipality: "",
      province: "",
      zipCode: ""
    };
  }

  export const initialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    employeeNumber: "",
    designation: "",
    contactNumber: "",
    emailAddress: "",
    address: {
      houseNumber: "",
      barangay: "",
      municipality: "",
      province: "",
      zipCode: ""
    },
    date: {
      dateOfBirth: "",
      dateOfHiring: ""
    },
    parents: {
      fathersName: "",
      mothersName: ""
    }
  }