<template>
 <div>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        width="850px"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            class="mr-5 text-white"
            v-bind="props"
          >
            Create
          </v-btn>
        </template>
        <v-card>
          <v-form ref="form">

            <v-card-title>
              <span class="text-h5">Employee Enrolment</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>              
                <NameFields 
                  v-model:firstName="firstName"
                  v-model:middleName="middleName"
                  v-model:lastName="lastName"
                />             
                </v-row>
                <v-row>
                  <v-col cols="4">
                  <v-text-field 
                  label="Employee Number" 
                  v-model="employeeNumber" 
                  :rules="[rules.required]"
                  maxLength="6"
                  ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                  <v-text-field 
                  label="Designation" 
                  v-model="designation" 
                  :rules="[rules.required]"
                  maxLength="45"
                  ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                  <v-autocomplete
                    v-model="role"
                    :items="items"
                    hint="If non-admin role, select 'employee'"
                    persistent-hint
                    outlined
                    dense
                    chips
                    small-chips
                    clearable
                    label="Select Role"
                    multiple
                    :rules="[rules.required]"
                  ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col cols="3">
                    <v-text-field 
                    label="Mobile number" 
                    v-model="contactNumber" 
                    :rules="[rules.required]"
                    maxlength="11"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="5">
                  <v-text-field 
                  label="Email Address"
                  hint="Company provided email" 
                  v-model="emailAddress" 
                  :rules="[rules.email]"
                  ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field 
                    label="Password" 
                    v-model="password"
                    @click:append-inner="() => (value = !value)"               
                    :type="value ? 'password' : 'text'"
                    :append-inner-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"></v-text-field>
                  </v-col> 
                </v-row>

                <v-row>
                  <v-col
                  cols="6" >                
                    <v-file-input 
                    accept="image/*"
                    label="picture"
                    @change="imageContainer"
                    required
                    ></v-file-input>
                    
                  </v-col>
                  <v-col
                    cols="6"
                    >
                    <img src="photoUrl" alt="Upload photo" height="100" />
                  </v-col>                
                </v-row>

                <v-divider></v-divider> <br/>
                <v-row>
                  <AddressFieldGroup 
                    :prefilled="address"
                    :key="forceRefresh"
                    @on-location="onAddress"   
                  /> 
                </v-row>

                <v-divider></v-divider> <br/>

                <v-row>
                  <v-col cols="4">
                    <DatePicker 
                      label="Date of Birth:"
                      v-model="date.birthDay"
                    />

                    <DatePicker 
                      label="Date Hired:"
                      v-model="date.dateHired"
                    />
                  </v-col>

                  <v-col cols="8" class="mt-5">
                      <StatusOfEmployment 
                        :fillStatus="status"
                        :key="forceRefresh"
                        @on-status="onStatus"
                      />
                  </v-col>

                </v-row>

                <v-divider></v-divider> <br/>

                <v-row>
                  <Contacts 
                  v-model:fathersName="parents.fathersName"
                  v-model:mothersName="parents.mothersName"/>
                </v-row>

                <v-row>

                <v-divider></v-divider> <br/>  
                            
                <v-spacer></v-spacer>
                  
                    <v-card-actions>                   
                      <GenericBtn 
                      @click.prevent="onSubmit"
                      @click="dialog=false"
                      color="green" 
                      variant="outlined"
                      type="submit"
                      buttonText="Create" 
                      />
                    </v-card-actions>
                
                </v-row>
              
              </v-container>
            </v-card-text>

          </v-form>
        </v-card>
    </v-dialog>
  </v-row>
 </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { doc, addDoc } from 'firebase/firestore';
import { getStorage, ref as storageReference, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, createUserWithEmailAndPassword} from 'firebase/auth';
import GenericBtn from '../../components/utilities/buttons/GenericBtn.vue';
import AddressFieldGroup from '@/components/utilities/AddressBar.vue';
import NameFields from '@/components/utilities/NameFields.vue';
import DatePicker from '@/components/utilities/DatePicker.vue';
import Contacts from '@/components/utilities/Contacts.vue';
import StatusOfEmployment from '@/components/humanResource/StatusOfEmployment.vue';
import { rules } from '@/components/utilities/validation.js';
import { employees } from '@/firebase';
import { initialState } from '@/components/utilities/others';

    const address = ref({
      houseNumber: "",
      barangay: "",
      municipality: "",
      province: "",
      zipCode: ""
    });

    const date = ref({
      birthDay: "",
      dateHired: ""
    });

    const parents = ref({
      fathersName: "",
      mothersName: ""
    });

    const dialog = ref(false);
    const auth = getAuth();
    const forceRefresh = ref(0);
    const firstName = ref(""); 
    const middleName = ref("");  
    const lastName = ref("");
    const employeeNumber = ref("");
    const designation = ref("");
    const contactNumber = ref("");
    const emailAddress = ref("");
    const password = ref("");
    let photoUrl = ref();
    let preview = ref();
    const status = ref("");
    let image = ref();  
    const form = ref(null);
    const items = ref(['HR', 'employee']);
    const role = ref();
    const value = ref(null);

    // For creating user Id reference inside the collection
    const signup =()=> {
    return new Promise((resolve)=>{        
    createUserWithEmailAndPassword(auth, emailAddress.value, password.value)
      .then((value) => {  
        resolve(value)
      })
      .catch(error => {
        console.log(error.code); 
        alert('Please fill up the details');  
      });
        })
    }
    
     // working... pushing data and image to firestore 
    const onSubmit = async () => {  
    
      if(form.value.validate()) {
          let authId = await signup();
          const storage = getStorage();
          let path = 'public/' + `${authId.user.uid}/` + image.name;
          const storageRef = storageReference(storage, path );

          uploadBytes(storageRef, image).then((snapshot) => {
              getDownloadURL(storageRef).then((url) => {
                path = url;  
              })
              .catch((error) => {
              alert(error.message);
              })
          });

          const fields = {
            firstName: firstName.value,
            middleName: middleName.value,
            lastName: lastName.value,
            employeeNumber: employeeNumber.value,
            designation: designation.value,
            contactNumber: contactNumber.value,
            emailAddress: emailAddress.value,
            filepath: path,
            authId: authId.user.uid,
            role: role.value,
            address: {
              houseNumber: address.value.houseNumber,
              barangay: address.value.barangay,
              municipality: address.value.municipality,
              province: address.value.province,
              zipCode: address.value.zipCode
            },
            date: {
              dateOfBirth: date.value.birthDay,
              dateOfHiring: date.value.dateHired
            },
            parents: {
              fathersName: parents.value.fathersName,
              mothersName: parents.value.mothersName
            },
            status: status.value
          };     
          console.log(fields);
          await addDoc(employees, fields);  
          alert('User was created.'); 
           firstName.value = "";
           middleName.value = "";
           lastName.value = "";
           employeeNumber.value = "";
           designation.value = "";
           contactNumber.value = "";
           emailAddress.value = ""; 
           password.value = ""; 
           address.value = {
            houseNumber: "",
            barangay: "",
            municipality: "",
            province: "",
            zipCode: ""
           };
           date.value.birthDay = "";
           date.value.dateHired = "";
           parents.value.fathersName ="";         
           parents.value.mothersName ="";       
      }      
   }

   // method for getting image file
    function imageContainer(event) {
       preview = document.querySelector('img');
       const files = event.target.files;
       photoUrl = new FileReader();
       photoUrl.addEventListener('load', () => {
         preview.src = photoUrl.result;
         photoUrl = photoUrl.result;
       })
       photoUrl.readAsDataURL(files[0]);
       image = files[0];
    }

    const onAddress = (receivedAddress) => {
      address.value = { ...receivedAddress };
    };

    const onStatus = (stat) => {
      status.value = stat;
    }

</script>