<template>
    <v-img src="@/assets/MSO_Logo.gif" alt="Logo" id="img"/>
</template>

<style scoped>

#img {
    width: 100px;   
    top: 10px;
    right: 10px;
    position: absolute;
}
</style>