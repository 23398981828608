<template> 
  <LoginAdmin />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import LoginAdmin from '../../components/LoginUser.vue';


export default defineComponent({
  name: 'HomeView',

  components: {
    LoginAdmin,
  
  },
});
</script>

<style scoped>
* {
  background-color: #558b2f;
}
</style>
