<template>
 <v-app>
  <v-main id="main">
   <!-- <NavBar @open="showPortal=true"/> -->
   <NavBar />
    <div>
    <v-card
      flat
      class="d-flex align-center justify-center pa-0 mx-auto" 
      max-width="550" 
      min-height="76" 
      id="title" 
    >
    <div :class="`text-lg-h4 `">
      DashBoard
    </div>          
    </v-card>
      
    <v-container>
      <v-row >
        <v-col>               
          <v-col md="4">
            <v-text-field          
             v-model="search" 
             @input="searchMain" 
             prepend-inner-icon="mdi-magnify" 
             placeholder="Look for Last name..."
             maxLength="20"
             ></v-text-field>
          </v-col>
          <v-col cols="4">
            <div class="item error" v-if=" !search && !store.users.length">
              <p>No results found!</p>
            </div>
          </v-col>
                      
          <v-card class="mt-n10" >           
            <v-table >
              <thead>
                <tr>
                  <th class="text-center">Name</th>
                  <th class="text-center">Designation</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
               <tr v-for=" user in newVal " :key="user.id" >
                <td class="text-center">{{ user.firstName }} {{ user.lastName }}</td>
                <td class="text-center">{{ user.designation }}</td>
                <td >                                         
                   <v-row justify="center">  
                    <v-col cols="2" sm="3">                  
                      <v-tooltip>
                        <template v-slot:activator="{ props }">                    
                          <router-link :to="{ name: 'forCompany', params: { id: user.id } }" target="_blank"
                          rel="noopener noreferrer" id="details" >
                              <v-btn
                              density="compact"
                              flat 
                              v-bind="props"
                              >
                              <v-icon color="success" size="x-large">mdi-note-outline</v-icon>
                              </v-btn>
                          </router-link>
                        </template>
                        <span>Details</span>
                      </v-tooltip>                    
                    </v-col>

                    <v-col cols="2" sm="3">
                      <v-tooltip>
                        <template v-slot:activator="{ props }">
                          <router-link :to="{ name: 'editUser', params: { id: user.id} }" id="edit" target="_blank"
                          rel="noopener noreferrer">
                            <v-btn 
                            density="compact"                       
                            flat  
                            class="ml-5"                            
                            v-bind="props"
                            >
                            <v-icon color="grey" size="x-large">mdi-pencil-outline</v-icon>
                            </v-btn>
                          </router-link>
                        </template>
                        <span>Update</span>
                      </v-tooltip> 
                    </v-col>                                                             
                   </v-row>                                                           
                </td>
               </tr>
              </tbody>
            </v-table>           
          </v-card>
        </v-col> 
              
      </v-row>       
    </v-container>
      
    </div> 
   <!-- <div v-if="showPortal">
    <Alternate  @close="showPortal = false"/>
   </div> -->

  </v-main>
 </v-app>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { doc, deleteDoc, getDoc } from 'firebase/firestore';
import { employees } from '@/firebase';
import { useStore } from '@/stores/userStore';
import { useRoute } from 'vue-router';
//import Alternate from './AlternativePortal.vue';
import NavBar from '../../components/utilities/NavBar.vue';
import ForCompany from '@/components/humanResource/ForCompany.vue';

export default ({  
  name: "hrView",
  components: { 
    //Alternate, 
    NavBar,
    ForCompany,
  },

  setup() {
    const store = useStore();
    const staff = store.getData();
    const search = ref("");
    let newVal = ref([]);
    let showPortal = ref(false);     

    const searchMain = computed(() => {  
      newVal.value = store.users.filter(user => user.lastName?.toLowerCase()
                     .includes(search.value.toLowerCase()));
    })
    
    // onMounted(() => {
    //   // newVal.value = store.users;
    //   console.log(staff);
    // })
    
    return {
      store, 
      showPortal,
      search,
      newVal,
      searchMain,
      onMounted
    }
  },
    
})
</script>

<style scoped>
#main {
  background: aliceblue;
}

h4 {
  margin-top: 10px;
  color: #384441; 
  padding-right: 200px;
}

#details {
  text-decoration: none; 
}

#edit {
  text-decoration: none; 
}

#title {
  background: aliceblue;
}
</style>