<template>

    <v-btn        
    id="createBtn"  
    color="white"        
    class="ma-1"
    plain
    >
      {{ buttonText }}
    </v-btn>

</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            default: () => "Label"
        }
    },
}
</script>
