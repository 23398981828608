import { defineStore } from 'pinia';
import { employees, roles } from '@/firebase';
import { useRoute } from 'vue-router';
import { getDoc, doc, getDocs, query, where } from 'firebase/firestore';
import {  getStorage, ref, getDownloadURL } from 'firebase/storage';
import { onMounted, watchEffect } from 'vue';

export const useStore = defineStore("userStore", {  
    state: () => ({
        address: {
            street: '',
            barangay: '',
            city: '',
            province: '',
            zipCode: ''
        },
        authId: '',
        date: {
            dateOfBirth: '',
            dateOfHiring: '',
        },
        selectedUserUid: '',
        firstName: '',
        middleName: '',
        lastName: '',
        designation: '',
        employeeNumber: '',
        emergencyName: '',
        emergencyNumber: '',
        benefitFile: [],
        gender: '',
        age: '',
        contactNumber: '',
        emailAddress: '',
        photoUrl: '',
        parents: {
            fathersName: '',
            mothersName: ''
        },
        path: '',
        str: '',
        users: [], 

    }),
   
    actions: {
        // this function is to  fill-in details of ForCompany and ForClient
        async fill() {     
            const route = useRoute();
            let userId = route.params.id;
            let docRef = doc(employees, userId);           
            let docData = await getDoc(docRef);
            let userRef = docData.data();

            this.photoUrl = await this.convertPath(userRef.filepath);
            this.address = userRef.address;
            this.authId = userRef.authId;
            this.date = userRef.date;
            this.firstName = userRef.firstName;
            this.middleName = userRef.middleName;
            this.lastName = userRef.lastName;
            this.designation = userRef.designation;
            this.employeeNumber = userRef.employeeNumber;
            this.emergencyName = userRef.emergencyName;
            this.emergencyNumber = userRef.emergencyNumber;
            this.benefitFile = await this.convertPathArrayed(userRef.benefitFile);
            this.gender = userRef.gender;
            this.dateOfHiring = userRef.dateOfHiring;
            this.age = userRef.age;
            this.contactNumber = userRef.contactNumber;            
            this.emailAddress = userRef.emailAddress;

            this.str = 'https://hr.medisureonline.com/forClient/' + userId;
        },

        // this function is to fill-in data table for HR view
        async getData() {  
            const fetchData = await getDocs(employees);
            this.users = [];
            fetchData.forEach((user) => {
              let userId = user.data();
              userId.id = user.id;
              this.users.push(userId); 
            }) 
            return this.users
            //console.log('HR dataTable:',this.users);   
        },

        async convertPath(path) {
           const storage = getStorage();
           let url = [];
           try {
             const starsRef = ref(storage, path);
             url = await getDownloadURL(starsRef)  
             return url; 
           }
           catch(error) {
            switch (error.code) {                
                case 'storage/object-not-found':
                    // File doesn't exist
                    break;
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                // ...
                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
                }
           }
           return url;
        }, 

        async convertPathArrayed(paths) {
            let urls = [];
            for(let path of Array.from(paths || {})){     
            const storage = getStorage();

            try {
              const starsRef = ref(storage, path);
              let url = await getDownloadURL(starsRef) 
              urls.push({url: url, path: path}) 

            }
            catch(error) {
             switch (error.code) {                
                 case 'storage/object-not-found':
                     // File doesn't exist
                     break;
                 case 'storage/unauthorized':
                     // User doesn't have permission to access the object
                     break;
                 case 'storage/canceled':
                     // User canceled the upload
                     break;
                 // ...
                 case 'storage/unknown':
                     // Unknown error occurred, inspect the server response
                     break;
                 }
            }
         }     
         return urls;
        }       
 },
   
});