<template>
  <v-card>
    <div class="pt-6 text-center">
      <strong>{{ header }}</strong>
    </div>

    <div v-for="(file, index) in store.benefitFile" :key="index" >
      <span v-show="file.path">
        <v-dialog width="550px">
          <template v-slot:activator="{ props }" >
            <v-btn v-bind="props" flat >
              {{ file.path }}
            </v-btn>
          </template>
           <v-card-title>
            <iframe :src="file.url" width="500px" height="600px"></iframe>
           </v-card-title>
        </v-dialog>
      </span>
      <span v-show="!file.path">(None)</span>
    </div>
    
    <div align="center">
     <v-dialog v-model="dialog" width="350">      
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          class="ma-1 text-white"
          color="#68b5c7"
          small
          @click.stop="dialog = true"        
        >
          +
        </v-btn>
      </template>         
       
      <v-card align="center">
        <div class="text-center">
          <input 
           id="input"      
           multiple
           type="file"
           label="Add"
           accept="application/pdf,image/*"
           @change="selectFile"
          />
        </div>

        <div v-for="(file, index) in targetFile" :key="index">
          {{ file.name }}
          <span>
            <v-btn 
            flat
             size="x-small" 
             color="error"  
             @click="removeFile(index)"
             class="rounded-circle d-inline-block">
              x
            </v-btn>
          </span>
          
        </div>

       <div align="center">
         <v-btn 
          @click="upload"
          small 
          width="200"
          :disabled="!isDisabled"
          @click.stop="dialog = false"
          >
            Upload
         </v-btn>
       </div>
      </v-card>
    </v-dialog>
   </div>
  </v-card>
</template>

<script setup>

import { computed, onMounted, ref } from "vue";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useStore } from '@/stores/userStore';
import { getStorage, ref as storageReference,uploadBytes, getDownloadURL  } from "firebase/storage";
import { employees } from '../../../firebase';

const props = defineProps({
    header: String
})

let targetFile = ref([]);
const filePaths = [];
const fileUrls = [];
let dialog = ref(false);
let showResult = ref(false);
let uuid = require("uuid");
const store = useStore();
const fill = store.fill();

const isDisabled = computed(() => {
    return targetFile.value.length > 0;
})

const selectFile = (e) => {
    targetFile.value = [...e.target.files];  // mechanism for selecting files
}

const removeFile = (index) => {
  console.log(index);
    if(index > -1) {
      targetFile.value.splice(index, 1);    // mechanism for removing files
    }   
}

const upload = async () => {
  const storage = getStorage();

  for(let key in targetFile.value) {
    let individualFile = targetFile.value[key];
    let storagePath =  `${ store.selectedUserUid }/` + individualFile.name;

    //To create path reference in firestore
    filePaths.push(storagePath);
 
    let fileReference = storageReference(storage, storagePath);
    await uploadBytes(fileReference, individualFile);

    try{
      let generatedUrl = await getDownloadURL(fileReference);
      fileUrls.push(generatedUrl);
    }
    catch(error) {
      alert(error);
    }
  }

  //let data = {benefitFile: filePaths};
  console.log(employees);
  const docRef = doc(employees, store.selectedUserUid);
  await updateDoc(docRef, {
    benefitFile: arrayUnion(...filePaths)  
  });
  alert("Files was uploaded");  
  
}
</script>