<template>
<v-app>
  <v-main >
    <v-layout align="center" full-height>          
      <v-container fluid>
          <CompanyLogo />
                
          <h2 id="medi" class="text-xs">Medi<span id="sure">Sure</span><sup>Plus</sup></h2>
                                  
          <v-row id="title">
            <v-col>
              <v-card  class="mt-8 text-medium text-center " id="card" max-width="344">

              <v-col class="mt-8">

                <form type="submit" @keyup.enter="signIn" id="form">
                        
                  <v-text-field 
                   id="userName"
                   label="User Name / Email" 
                   variant="outlined" 
                   v-model="email" 
                   maxLength="40"
                   :rules="[rules.email]"
                   ></v-text-field>

                  <v-text-field 
                   id="passWord"
                   label="password" 
                   variant="outlined" 
                   v-model="password" 
                   type="password" 
                   maxLength="15"
                   ></v-text-field>

                  <v-row justify="center">
                  <GenericBtn @click="signIn" buttonText="Login" id="formBtn"/>
                  </v-row>
                        
                </form>
                                            
              </v-col>
                      
            </v-card>  
            </v-col>
          </v-row>     
                
      </v-container>
    </v-layout>
  </v-main> 
</v-app>   
</template>

<script>
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { getProfile } from '../firebase';
import { ref } from 'vue';
import router from '@/router';
import GenericBtn from './utilities/buttons/GenericBtn.vue';
import CompanyLogo from './utilities/CompanyLogo.vue';
import CompanyName from './utilities/CompanyName.vue';
import { useStore } from '@/stores/userStore.js';
import { rules } from '@/components/utilities/validation.js';

export default ({
  name: 'HomeView',
  
  components: {
   CompanyLogo,
   CompanyName,
   GenericBtn,
  },

 setup() {
    const auth = getAuth();
    const store = useStore();
    const email = ref("");
    const password = ref("");    

    const signIn = async () => {
      try {
        let userCredential = await signInWithEmailAndPassword(auth, email.value, password.value)
     
        const user = userCredential.user; 
        // console.log(user);
        let userProfile = await getProfile(user.uid);
        // console.log(userProfile);
         if(userProfile.role.includes('HR')){
          router.push('/hrView');
         }else if(userProfile.role.includes('employee')) {
          router.push("/employeeView/" + userProfile.id);
         }
      }
      catch(err) {
        alert("Please enter valid username and password");
        console.log(err);
      }
                               
    }

    return {
      email,
      password,
      signIn,
      rules
    }
  }

});
</script>

<style scoped>

#card {
   background-color: aliceblue;
   border-radius: 8px;
}

#medi {
    color: #bdbdbd;
    margin: 70px;
}

#title {
    margin-top: -80px;
}

#sure {
    color: #deded8;
}

</style>